/* istanbul ignore file */
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import useTokenStore from '@/store/token/useTokenStore';
import useProfileStore from '@/store/profile/useProfileStore';
import { RouteAuthenticationRequirement } from '@/shared/types/generic';
import usePreventDirectRouteChange from '@/composables/usePreventDirectRouteChange/usePreventDirectRouteChange';
import Routes from '@/shared/types/routes';
import useRolesStore from '@/store/roles/useRolesStore';
import useDrawerLinks from '@/composables/useDrawerLinks/useDrawerLinks';

const { preventDirectRouteChange } = usePreventDirectRouteChange();

const OrganizationsView = () =>
  import(
    /* webpackChunkName: "organizations" */ '@/views/OrganizationsView/OrganizationsView.vue'
  );
const AddOrganization = () =>
  import(
    /* webpackChunkName: "organizations" */ '@/views/AddOrganizationView/AddOrganizationView.vue'
  );
const LoginView = () =>
  import(
    /* webpackChunkName: "authentication" */ '@/views/LoginView/LoginView.vue'
  );
const ForgotPasswordView = () =>
  import(
    /* webpackChunkName: "authentication" */ '@/views/ForgotPasswordView/ForgotPasswordView.vue'
  );
const ChangePasswordView = () =>
  import(
    /* webpackChunkName: "authentication" */ '@/views/ChangePasswordView/ChangePasswordView.vue'
  );
const AdminsView = () =>
  import(/* webpackChunkName: "admins" */ '@/views/AdminsView/AdminsView.vue');
const Translations = () =>
  import(
    /* webpackChunkName: "translations" */ '@/views/TranslationsView/TranslationsView.vue'
  );

const ChallengeScheduler = () =>
  import(
    /* webpackChunkName: "challengesScheduler" */ '@/views/ChallengeSchedulerView/ChallengeSchedulerView.vue'
  );

const ChallengeRewards = () =>
  import(
    /* webpackChunkName: "challengesScheduler" */ '@/views/ChallengeRewardsView/ChallengeRewardsView.vue'
  );

const NoConnectionView = () =>
  import(
    /* webpackChunkName: "disconnected" */ '@/views/NoConnectionView/NoConnectionView.vue'
  );
const MaintenanceView = () =>
  import(
    /* webpackChunkName: "disconnected" */ '@/views/MaintenanceView/MaintenanceView.vue'
  );
const AccountSettingsView = () =>
  import(
    /* webpackChunkName: "profile" */ '@/views/AccountSettingsView/AccountSettingsView.vue'
  );
const ProfileView = () =>
  import(
    /* webpackChunkName: "profile" */ '@/views/ProfileView/ProfileView.vue'
  );
const EditProfileView = () =>
  import(
    /* webpackChunkName: "profile" */ '@/views/EditProfileView/EditProfileView.vue'
  );

const SecurityView = () =>
  import(
    /* webpackChunkName: "profile" */ '@/views/SecurityView/SecurityView.vue'
  );
const AppChangePasswordForm = () =>
  import(
    /* webpackChunkName: "profile" */ '@/components/app/AppChangePasswordForm/AppChangePasswordForm.vue'
  );
const DeleteAccountView = () =>
  import(
    /* webpackChunkName: "profile" */ '@/views/DeleteAccountView/DeleteAccountView.vue'
  );
const PartnersView = () =>
  import(
    /* webpackChunkName: "partners" */ '@/views/PartnersView/PartnersView.vue'
  );

const AddPartnersView = () =>
  import(
    /* webpackChunkName: "add-partners" */ '@/views/PartnersView/AddPartnersView/AddPartnersView.vue'
  );

const FarewellView = () =>
  import(
    /* webpackChunkName: "farewell" */ '@/views/FarewellView/FarewellView.vue'
  );

const OrganizationSettingsView = () =>
  import(
    /* webpackChunkName: "organization-settings" */ '@/views/OrganizationSettingsView/OrganizationSettingsView.vue'
  );

const OrganizationDetailsView = () =>
  import(
    /* webpackChunkName: "organization-settings" */ '@/views/OrganizationDetailsView/OrganizationDetailsView.vue'
  );

const EditOrganizationDetailsView = () =>
  import(
    /* webpackChunkName: "organization-settings" */ '@/views/EditOrganizationDetailsView/EditOrganizationDetailsView.vue'
  );

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
  {
    path: '/',
    redirect: '/organizations',
  },
  {
    path: '/first-login',
    redirect: '/organizations',
    beforeEnter: () => {
      const tokenStore = useTokenStore();

      tokenStore.setToken('', '', '');
    },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPasswordView,
  },
  {
    path: '/change-password',
    name: 'change-password',
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.UNIVERSAL,
    },
    component: ChangePasswordView,
    beforeEnter: (_to, from, next) => {
      if (from.name) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/organizations',
    name: 'organizations',
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },
    children: [
      {
        path: '',
        name: 'organizations',
        component: OrganizationsView,
        meta: {
          default: true,
        },
      },
      {
        path: 'add',
        name: 'addOrganization',
        component: AddOrganization,
        meta: {
          miniMode: true,
        },
      },
    ],
  },
  {
    path: Routes.ADMINS,
    name: 'admins',
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },
    component: AdminsView,
  },
  {
    path: '/translations',
    name: 'translations',
    component: Translations,
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },
  },
  {
    path: '/challenge-scheduler',
    name: 'challengeScheduler',
    component: ChallengeScheduler,
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },
  },
  {
    path: '/challenge-rewards',
    name: 'challengeRewards',
    component: ChallengeRewards,
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },
  },
  {
    path: '/502',
    name: 'no-connection',
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },
    component: NoConnectionView,
    beforeEnter: (to, from, next) => preventDirectRouteChange(from, to, next),
  },
  {
    path: '/503',
    name: 'maintenance',
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },
    component: MaintenanceView,
    beforeEnter: (to, from, next) => preventDirectRouteChange(from, to, next),
  },
  {
    path: Routes.ACCOUNT_SETTINGS,
    name: 'accountSettings',
    redirect: '/account-settings/profile',
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },
    component: AccountSettingsView,
    children: [
      {
        path: 'security',
        name: 'security',
        children: [
          {
            path: '',
            name: 'securityView',
            component: SecurityView,
          },
          {
            path: 'change-password',
            name: 'changePasswordForm',
            component: AppChangePasswordForm,
          },
          {
            path: 'delete-account',
            name: 'deleteAccount',
            component: DeleteAccountView,
          },
        ],
      },
      {
        path: 'profile',
        name: 'profile',
        children: [
          {
            path: '',
            name: 'profileView',
            component: ProfileView,
          },
          {
            path: 'edit',
            name: 'editProfile',
            component: EditProfileView,
          },
        ],
      },
    ],
  },
  {
    path: Routes.PARTNERS,
    name: 'partners',
    meta: {
      permissions: ['AD_005'],
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },
    children: [
      {
        path: '',
        name: 'viewPartners',
        meta: {
          default: true,
        },
        children: [
          {
            path: '',
            name: 'partnersTable',
            component: PartnersView,
            meta: {
              default: true,
            },
          },
          {
            path: 'add',
            name: 'addPartners',
            component: AddPartnersView,
            meta: {
              miniMode: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/farewell',
    name: 'farewell',
    component: FarewellView,
    beforeEnter: (to, from, next) => preventDirectRouteChange(from, to, next),
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.UNAUTHENTICATED,
    },
  },
  {
    path: '/organization-settings',
    name: 'organization-settings',
    redirect: '/organization-settings/details',
    component: OrganizationSettingsView,
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },
    children: [
      {
        path: 'details',
        name: 'organizationDetails',
        children: [
          {
            path: '',
            name: 'organizationDetailsView',
            component: OrganizationDetailsView,
          },
          {
            path: 'edit',
            name: 'organizationDetailsEdit',
            component: EditOrganizationDetailsView,
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, _from, next) => {
  const { token } = useTokenStore();

  const { userProfile, loadProfile } = useProfileStore();

  const { loadProfileRoleLevel, isPermissionActive } = useRolesStore();

  const { updateDrawerNavigationLinks, getNextAllowedDrawerLink } =
    useDrawerLinks();

  const routeRequiresAuthentication = to.matched.some(
    (route) =>
      route.meta.authenticationRequirement ===
      RouteAuthenticationRequirement.AUTHENTICATED,
  );

  const routeHasUniversalAccess =
    to.meta.authenticationRequirement ===
    RouteAuthenticationRequirement.UNIVERSAL;

  let isAuthenticated = token !== '';

  const profileIsNotLoaded = userProfile === undefined;

  if (isAuthenticated && profileIsNotLoaded) {
    await Promise.all([loadProfileRoleLevel(), loadProfile()]);

    isAuthenticated = token !== '';
  }

  updateDrawerNavigationLinks(isPermissionActive);

  if (
    !routeRequiresAuthentication &&
    !routeHasUniversalAccess &&
    isAuthenticated
  ) {
    next('/');

    return;
  }

  if (
    routeRequiresAuthentication &&
    !isAuthenticated &&
    !routeHasUniversalAccess
  ) {
    next('/login');

    return;
  }

  const permissions: string[] = (to.meta.permissions as string[]) ?? [];

  const noPermissionIsActive: boolean =
    permissions.some((permissionCode: string) =>
      isPermissionActive(permissionCode),
    ) === false;

  if (permissions.length > 0 && noPermissionIsActive) {
    next(getNextAllowedDrawerLink());

    return;
  }

  next();
});

export default router;
